<template>
  <div class="right">
    <v-text-field
      v-model="search"
      autocomplete="off"
      type="text"
      hide-details
      single-line
      solo-inverted
      clearable
      dense
      flat
      class="pr-5"
      prepend-inner-icon="mdi-magnify"
      placeholder="Documents attachés"
      style="width: 490px"
      :loading="loading"
    >
    </v-text-field>

    <formlist
      :title="'Documents attachés'"
      :list="list"
      :showform="!isListClose"
      :listheaders="items_headers"
      :key="fl"
      @close="closeList"
      :master="true"
      :width="'1200px'"
      :showedit="false"
    >
    </formlist>
  </div>
</template>

<script>
import DOCS from "@/graphql/DOCS.gql";
function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}
export default {
  name: "DefaultSearch",
  components: {
    formlist: () => import("@/components/FormList.vue"),
  },
  data: () => ({
    search: null,
    items_list: [],
    items_headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "N° Document",
        value: "nodoc",
        selected: true,
      },

      {
        text: "Date Document",
        value: "doc_date",
        enum: "DOC_DATE",
        slot: "date",
        selected: true,
      },
      {
        text: "Ref",
        align: "text-left",
        value: "ref",
        enum: "REF",
        selected: true,
      },
      {
        text: "Description",
        align: "text-left",
        value: "description",
        enum: "DESCRIPTION",
        selected: true,
      },
      {
        text: "Nom Fichier",
        align: "text-left",
        value: "filename",
        enum: "FILENAME",
        slot: "download",
        selected: true,
      },
      {
        text: "Taille",
        align: "text-left",
        value: "size",
        enum: "SIZE",
        selected: true,
      },
      {
        text: "Type",
        align: "text-left",
        value: "doc_type_name",
        selected: true,
      },
    ],
    isListClose: true,
    fl: 0,
    loading: false,
    options: {},
  }),
  watch: {
    search: debounce(function () {
      this.search_doc();
    }, 1000),
  },
  mounted() {},
  computed: {
    list() {
      return this.items_list.filter(
        (elm) =>
          this.$store.state.auth.includes(elm.auth) || this.$store.state.isadmin
      );
    },
  },
  methods: {
    closeList() {
      this.isListClose = true;
    },
    async search_doc() {
      var where = null;
      if (this.search) {
        var i;
        var Wherelist = [];
        var x = "%";
        x = x.concat(this.search, "%");
        for (i in this.items_headers) {
          if (this.items_headers[i].selected && this.items_headers[i].enum) {
            Wherelist.push({
              column: this.items_headers[i].enum,
              operator: "LIKE",
              value: x,
            });
          }
        }
        if (Wherelist.length) where = { OR: Wherelist };

        let v = {
          where: where,
        };
        let r = await this.requette(DOCS, v);
        if (r) {
          this.items_list = r.alldocs;
        }
        this.isListClose = false;
        this.fl++;
      }
    },

    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
  },
};
</script>
